<template>
  <div v-if="displayOnly">{{ displayValue ? displayValue : '' }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :vid="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="computedVeeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <div class="typeahead" ref="typeahead" @keydown="keydownHandler" @focusin="isFocused = true" @focusout="handleBlur">
      <input class="form-control typeahead-suggestion"
        type="text"
        v-model="suggestionValue"
        autocomplete="off"
        disabled
        tabindex="-1" />
      <div :class="{ 'input-group': showSearchSymbol }">
        <div class="typeahead-input-container">
          <input
            ref="typeaheadInput"
            class="form-control typeahead-input"
            data-automation-typeahead
            type="text"
            autocomplete="off"
            :name="name"
            :id="id || name"
            :placeholder="placeholder"
            v-model="displayValue"
            @input="debounceInput()"
            @keyup="getCursorLocation"
            :disabled="disabled"
          />
          <div v-show="infoMessage.length && isFocused" class="typeahead-results" :class="{ '-full-width': fullWidthDropdown }" tabindex="1">
            <div class="typeahead-results-item">{{ infoMessage }}</div>
          </div>
          <div v-show="results.length > 0 && isFocused" ref="results" class="typeahead-results" :class="{ '-full-width': fullWidthDropdown }" @scroll="resultsScroll" tabindex="1">
            <a
              v-for="(result, i) in results"
              :key="i"
              class="typeahead-results-item"
              :class="{ '-active': i === activeItem, 'text-nowrap': !wrapDropdownText }"
              :data-automation-typeahead-id="result[idValue]"
              :data-automation-typeahead-result-active="i === activeItem ? 'active' : null"
              href="#"
              ref="item"
              v-html="formatResult(result)"
              @mouseover="activeItem = i"
              @click="clickHandler"
            ></a>
          </div>
        </div>

        <span
          v-if="showSearchSymbol"
          :class="iconClass"
          class="input-group-addon"
        ></span>
      </div>
    </div>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
import debounce from "../utils/debounce";
import {matchSorter} from 'match-sorter'

// escapes string for regex
RegExp.quote = function (str) {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};

export default {
  name: "EnTypeahead",
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Minimum characters that need to be typed to start fetching data.
     */
    minChar: {
      type: Number,
      default: 2,
    },
    /**
     * Optional function that can be used to customize the response data directly after being fetched.
     * Accepts one argument which is the response data.
     */
    filterJson: {
      type: Function,
      default: null,
    },
    /**
     * Sets the key to be used for populating what gets shown in the dropdown from the response data.
     * Required if the response data are objects.
     */
    getValue: {
      type: String,
      default: null,
    },
    /**
     * Sets the key to be used as the id from the response data.
     */
    idValue: {
      type: String,
      default: "Id",
    },
    /**
     * Sets the key for where the list of data is in the response.
     */
    listLocation: {
      type: String,
      default: null,
    },
    /**
     * Sets the maximum number of items to show in the dropdown list.  This does not get sent to the server, that should be done in the additionalParams prop.
     */
    maxResults: {
      type: Number,
      default: null,
    },
    /**
     * Sets debounce timer in between key strokes in milleseconds.
     */
    requestDebounce: {
      type: Number,
      default: 300,
    },
    /**
     * Provides typeahead with static data to be searched instead of using a network call.
     */
    jsonData: {
      type: [Array, Object],
      default: null,
    },
    /**
     * URL used for network call.  Using as a function, the query value is passed as a param so that it can be constructed in the URL for each call.
     */
    url: {
      type: [Function, String],
    },
    /**
     * Method used for network calls.
     * @values POST, GET
     */
    httpVerb: {
      type: String,
      default: 'POST'
    },
    /**
     * Key value used for sending the query value.
     */
    queryParam: {
      type: String,
      default: 's'
    },
    /**
     * Additional key-value pairs to be sent with the network call.
     */
    additionalParams: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Additional options that can be added to the network call.  See https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
     */
    fetchOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Adds search icon as an input-group addon.
     */
    showSearchSymbol: {
      type: Boolean,
      default: false,
    },
    /**
     * Set to true to use as basic autocomplete using static json data.
     */
    autocomplete: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon CSS class for search symbol.
     */
    iconClass: {
      type: String,
      default: 'icon-search'
    },
    /**
     * Shows dropdown with static data on focus of the text input.
     */
    showAllOnFocus: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the dropdown width to extend to the end of the text input no matter what the results are.
     */
    fullWidthDropdown: {
      type: Boolean,
      default: true
    },
    /**
     * Wraps the dropdown item text if it is longer than the width of the text input.
     */
    wrapDropdownText: {
      type: Boolean,
      default: true
    },
    /**
     * Applies additional sorting of the results using match-sorter.  See https://www.npmjs.com/package/match-sorter.
     */
    matchSorting: {
      type: Boolean,
      default: true
    },
    /**
     * Adds an additional message that can be displayed if there are no results yet and the text input is focused.
     */
    infoMessage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      results: [],
      activeItem: 0,
      lastWord: "",
      isFocused: false,
      caret: {},
      enToken: '',
      suggestionValue: '',
      searchCache: {},
      selection: null
    };
  },
  mounted() {
    if (document.getElementById('en-session-token')) {
      this.enToken = document.getElementById('en-session-token').value
    }
    this.selection = this.value
  },
  computed: {
    queryVal() {
      if (this.autocomplete) {
        return this.lastWord;
      } else {
        return this.displayValue.trim();
      }
    },
    queryValDecoded() {
      return this.decodeHtml(this.queryVal)
    },
    requestUrl() {
      if (typeof this.url === "function") {
        return this.url(encodeURI(this.queryVal));
      } else {
        return this.url;
      }
    },
    fetchOptionsComputed() {
      const options = {
        method: this.httpVerb,
        headers: {
          'Content-Type': 'application/json',
          'ENToken': this.enToken
        },
      }

      const body = this.httpVerb.toLowerCase() === 'post' ? { body: JSON.stringify({ [this.queryParam]: this.queryVal, ...this.additionalParams }) } : {}

      return {
        ...options,
        ...body,
        ...this.fetchOptions
      }
    },
    computedVeeOptions() {

      return {
        ...this.veeOptions,
        detectInput: false
      }
    }
  },
  watch: {
    isFocused(bool) {
      if (bool && this.showAllOnFocus) {
        this.debounceInput()
      }
      if (!bool) {
        this.suggestionValue = ''
      }
    },
    results() {
      if (this.results.length === 0) {
        this.activeItem = 0
      } else if (this.activeItem >= this.results.length) {
        this.activeItem = this.results.length - 1
      } else {
        this.setSuggestion()
      }
    },
    activeItem() {
      this.setSuggestion()
    },
    displayValue(val) {
      this.setSuggestion()
      if (val !== this.selection) {
        this.selection = ''
      }
    },
    selection(val) {
      this.syncValidation(val)
    }
  },
  methods: {
    debounceInput: debounce(function () {
      const cachedResult = this.searchCache[this.queryVal.trim().toLowerCase()]
      if (this.jsonData && this.queryVal.length >= this.minChar) {
        this.handleJson()
      } else if (typeof cachedResult === 'object') {
        this.results = cachedResult
      } else if (
        !this.isEmptyOrSpaces(this.queryVal) &&
        this.queryVal.length >= this.minChar
      ) {
        this.fetchResults();
      } else {
        this.results = [];
      }
    }, 300),
    fetchResults() {
      fetch(this.requestUrl, {
        ...this.fetchOptionsComputed,
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json) {
            return;
          }
          if (this.filterJson) {
            this.results = this.filterJson(json);
          } else if (this.listLocation) {
            this.results = this.setResults(json[this.listLocation]);
          } else {
            this.results = this.setResults(json);
          }
          this.searchCache[this.queryVal.trim().toLowerCase()] = this.results
        })
        .catch((err) => console.error(err));
    },
    handleJson(skipFilter = false) {
      let data = this.jsonData;
      if (this.filterJson) {
        return (this.results = this.filterJson(this.jsonData));
      } else if (this.listLocation) {
        data = this.jsonData[this.listLocation];
      }

      if (skipFilter) {
        this.results = data
      } else {
        this.results = this.sortResults(data);
      }
    },
    defaultFilter(data) {
      return data.filter((item) => {
        const q = this.queryVal.toLowerCase();
        if (typeof item === "object" && this.getValue && typeof item[this.getValue] === 'string') {
          return item[this.getValue].toLowerCase().includes(q);
        } else {
          return item.toLowerCase().includes(q);
        }
      });
    },
    setResults(results) {
      if (!results) {
        return [];
      }
      if (this.matchSorting && results.length > 1) {
        results = this.sortResults(results)
      }
      if (this.maxResults === null) {
        return results;
      } else {
        return results.slice(0, this.maxResults);
      }
    },
    sortResults(results) {
      const opts = this.getValue ? {keys: [this.getValue]} : {threshold: matchSorter.rankings.WORD_STARTS_WITH}
      const sortedResults = matchSorter(results, this.queryVal, opts)
      return sortedResults.length ? sortedResults : results
    },
    formatResult(item) {
      let displayText = this.getValue === null ? item : item[this.getValue];
      displayText = this.decodeHtml(displayText)
      if (typeof displayText === 'object') {
        return console.error('You are returning an object without setting the getValue prop')
      }
      const regex = new RegExp(`(${RegExp.quote(this.queryValDecoded)})`, "gi");
      const found = displayText.match(regex);
      if (this.queryVal.length && found && displayText !== null) {
        displayText = displayText.replace(
          regex,
          `<strong>$1</strong>`
        );
      }

      return displayText;
    },
    clickHandler(e) {
      e.preventDefault()
      this.itemSelectionHandler();
    },
    keydownHandler(e) {
      if (this.results.length !== 0) {

        const selectionKeys = ["Enter", "Tab"];
        if (selectionKeys.includes(e.key)) {
          e.preventDefault();
          this.itemSelectionHandler();
          return
          // right arrow key code, select suggestion, clear results, re-fetch
        } else if (e.key === "ArrowRight" || e.keyCode === 39) {
          this.displayValue = this.suggestionValue
          // this.results = []
          this.debounceInput()
          // up arrow key code
        } else if (e.key === "ArrowUp" || e.keyCode === 38) {
          e.preventDefault();
          this.activeItem =
            this.activeItem === 0
              ? this.results.length - 1
              : (this.activeItem -= 1);
          this.scrollResultsWithActiveItem()
          // down arrow key code
        } else if (e.key === "ArrowDown" || e.keyCode === 40) {
          e.preventDefault();
          this.activeItem =
            this.activeItem === this.results.length - 1
              ? 0
              : (this.activeItem += 1);
          this.scrollResultsWithActiveItem()
        }
      };

      if (this.selection !== this.displayValue) {
        this.selection = null
      }
    },
    setSuggestion() {
      if (this.results.length && this.displayValue.length) {
        const item = this.results[this.activeItem]
        const activeSuggestion = this.getValue === null ? item : item[this.getValue];

        if (activeSuggestion && this.stringStartsWith(activeSuggestion, this.displayValue)) {
          const regex = new RegExp(`(${RegExp.quote(this.displayValue)})`, 'i')
          return this.suggestionValue = activeSuggestion.replace(regex,this.displayValue)
        }
      }

      return this.suggestionValue = ''
    },
    itemSelectionHandler() {
      const selectedItem = this.results[this.activeItem];
      const newVal =
        this.getValue === null ? selectedItem : selectedItem[this.getValue];
      if (this.autocomplete) {
        const start = this.displayValue.substring(
          0,
          this.caret.start - this.lastWord.length
        );
        this.displayValue =
          start +
          `${newVal} ` +
          this.displayValue.substring(
            this.caret.start,
            this.displayValue.length
          );
      } else {
        this.displayValue = newVal;
      }

      this.selection = newVal
      this.$emit("select", selectedItem)
      this.results = [];
      this.activeItem = 0;
    },
    getCursorLocation(e) {
      this.caret = {
        start: e.target.selectionStart,
        end: e.target.selectionEnd,
      };
      if (!this.displayValue) {
        return;
      }
      const result = /\S+$/.exec(this.displayValue.slice(0, this.caret.end));
      this.lastWord = result ? result[0] : null;
    },
    scrollResultsWithActiveItem() {
      const results = this.$refs.results,
            resultsHeight = results.offsetHeight,
            scrollTop = results.scrollTop,
            scrollPos = scrollTop + resultsHeight,
            activeItem = this.$refs.item[this.activeItem],
            itemHeight = activeItem.offsetHeight,
            offsetTop = activeItem.offsetTop,
            offsetBottom = offsetTop + itemHeight
            ;
      if (offsetBottom > scrollPos) {
        results.scrollTop = offsetBottom - resultsHeight
      } else if (offsetTop < scrollTop) {
        results.scrollTop = offsetTop
      }
    },
    syncValidation(newVal) {
      this.validator.syncValue(newVal)
      this.validator.validate()
    },
    /**
     * Clears out the text in the input.  Can also be achieved by binding v-model and setting that to an empty string.
     * @public
     */
    clearInput() {
      this.displayValue = ''
    },
    handleBlur(e) {
      const target = e.relatedTarget
      if (!this.$refs.typeahead.contains(target)) {
        this.isFocused = false
      }
      this.syncValidation(this.selection)
    },
    resultsScroll() {
      this.isFocused = true;
    },
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    stringStartsWith(str, substr) {
      return str.toLowerCase().startsWith(substr.toLowerCase())
    },
    decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerText = html;
      const text = txt.innerHTML

      txt.remove()
      return text
    }
  },
};
</script>
<style lang="scss" scoped>
.typeahead {
  position: relative;

  &-results {
    position: absolute;
    top: 100%;
    max-height: 300px;
    overflow: auto;
    z-index: 5;
    background: white;
    box-shadow: 0 2px 8px rgba(#000, 0.2);
    max-width: 100%;

    &.-full-width {
      min-width: 100%;
    }

    &-item {
      display: block;
      padding: 6px 12px;
      text-decoration: none;
      color: inherit;

      &.-active {
        background-color: $primary-color;
        color: color(white);
      }
    }
  }

  &-input {
    position: relative;

    &:not(:disabled) {
      background: transparent;
    }

    &-container {
      position: relative;
      flex: 1;
    }
  }

  &-suggestion {
    position: absolute;
    left: 0;
    top: 0;
    border-color: transparent;
    pointer-events: none;
    box-shadow: none;
    color: rgba($body-color, .8);
    background-color: #fff;
  }

  .input-group {
    display: flex;
    position: relative;

    &-addon {
      flex: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto;
    }
  }
}
</style>
